import type { BreadcrumbLink } from '#ui/types'

export default defineStore('breadcrumbs', () => {
  const links = ref<BreadcrumbLink[]>([])

  const homeCrumb: BreadcrumbLink = {
    label: '',
    icon: 'i-mdi-home-variant-outline',
    to: '/admin/dashboard'
  }

  // if we only want the home crumb we can call setCrumbs() with no arguments
  const setCrumbs = (updatedLinks: BreadcrumbLink[] = [], opts: { noHome?: boolean } = {}) => {
    const noHome = opts?.noHome
    // add the home crumb to the beginning of the array if we want it
    links.value = noHome ? [...updatedLinks] : [homeCrumb, ...updatedLinks]

    const lastIndex = links.value.length - 1
    // disable last link
    links.value[lastIndex] = {
      disabled: true,
      ...links.value[lastIndex]
    }
  }

  return {
    links,
    setCrumbs
  }
})
